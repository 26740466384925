/* how it works 
-----------------------------------------------------*/
/* general setting
-----------------------------------------------------*/
* {
  outline: transparent;
}

body {
  font-size: 16px;
  color: #004466;
  line-height: 1.75;
  background: #fafbfc;
  font-family: "Poppins", sans-serif;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

a {
  color: #006aff;
  -webkit-transition-duration: 0.2s;
       -o-transition-duration: 0.2s;
          transition-duration: 0.2s;
}
a:hover {
  text-decoration: none;
  color: #006aff;
}
a:focus {
  outline: none;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 1.28;
}

h1 {
  font-size: 40px;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
    line-height: 1.2em;
  }
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

p {
  margin-bottom: 22px;
}
p:last-child {
  margin-bottom: 0px;
}

input[type=submit] {
  border: none;
  -webkit-transition-duration: 0.2s;
       -o-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.page-header {
  margin: 0;
  padding: 0;
  border: none;
}

.page-main {
  min-height: 720px;
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
.page-main.how-it-works {
  padding: 0px 0px 50px;
}

.md-title-header h1 {
  text-transform: uppercase;
  padding: 14px 0px 11px;
  font-weight: 600;
  font-size: 30px;
}
@media screen and (max-width: 575px) {
  .md-title-header h1 {
    font-size: 28px;
  }
}

.md-tabs-normal .tab-nav {
  background: #c0dff2;
  margin: 0px 0px 40px;
}
.md-tabs-normal .tab-nav .signup {
  display: none;
}
.md-tabs-normal .tab-nav .nav {
  display: table;
}
.md-tabs-normal .tab-nav .nav .nav-item {
  text-transform: uppercase;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(1) a {
  background: #13538a;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(2) a {
  background: #2c92d5;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(2) a.active {
  background: #2c92d5;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(2) a.active::after {
  border-color: transparent transparent #2c92d5 #2c92d5;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(3) a {
  background: #38b6ff;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(3) a.active {
  background: #38b6ff;
}
.md-tabs-normal .tab-nav .nav .nav-item:nth-child(3) a.active::after {
  border-color: transparent transparent #38b6ff #38b6ff;
}
.md-tabs-normal .tab-nav .nav .nav-item a {
  background: #38b6ff;
  color: #ffffff;
  position: relative;
  display: block;
  padding: 36px 35px 36px 35px;
}
.md-tabs-normal .tab-nav .nav .nav-item a.active {
  background: #13538a;
}
.md-tabs-normal .tab-nav .nav .nav-item a.active::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -28px;
  top: -webkit-calc(100% - 1px);
  top: calc(100% - 1px);
  left: 50%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border: 19px solid black;
  border-color: transparent transparent #13538a #13538a;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
       -o-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.md-tabs-normal .tab-content .step {
  background: #c3effa;
  display: table;
  min-height: 122px;
  border-bottom: 2px solid #ffffff;
}
.md-tabs-normal .tab-content .step .title {
  width: 45%;
  position: relative;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  display: table-cell;
  vertical-align: middle;
  color: #ffffff;
}
.md-tabs-normal .tab-content .step .title:before {
  content: "";
  position: absolute;
  display: block;
  right: -59px;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 100%;
  background: #37c9ef;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
       -o-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.md-tabs-normal .tab-content .step .title .inner {
  display: table;
}
.md-tabs-normal .tab-content .step .title .number {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 20px 0px 20px;
}
.md-tabs-normal .tab-content .step .title .number span {
  display: block;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  color: #37c9ef;
  font-weight: 700;
  background: #ffffff;
  font-size: 24px;
  padding: 5px 0px 0px 0px;
}
.md-tabs-normal .tab-content .step .title .is-title {
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px);
  display: table-cell;
  vertical-align: middle;
  line-height: 1.25;
}
.md-tabs-normal .tab-content .step .step-content {
  width: 55%;
  display: table-cell;
  vertical-align: middle;
  color: #236094;
  padding: 20px 20px 20px 80px;
}
.md-tabs-normal .tab-content .step:nth-child(1) .title {
  background: #37c9ef;
}
.md-tabs-normal .tab-content .step:nth-child(2) {
  background: #c0dff2;
}
.md-tabs-normal .tab-content .step:nth-child(2) .title {
  background: #2c92d5;
}
.md-tabs-normal .tab-content .step:nth-child(2) .title:before {
  background: #2c92d5;
}
.md-tabs-normal .tab-content .step:nth-child(3) {
  background: #c4e9ff;
}
.md-tabs-normal .tab-content .step:nth-child(3) .title {
  background: #38b6ff;
}
.md-tabs-normal .tab-content .step:nth-child(3) .title:before {
  background: #38b6ff;
}
.md-tabs-normal .tab-content .step:last-child {
  border: none;
}
.md-tabs-normal .tab-content .tab-content-detail .title {
  border-bottom: 2px solid #191919;
  color: #13538a;
  font-weight: 700;
  font-size: 22px;
  padding: 74px 0px 17px;
  margin: 0px 0px 20px 0px;
  font-weight: 600;
}
@media screen and (max-width: 575px) {
  .md-tabs-normal .tab-nav .nav .nav-item a {
    padding: 40px 20px 40px 20px;
  }
  .md-tabs-normal .tab-content .step {
    display: block;
  }
  .md-tabs-normal .tab-content .step .title {
    width: 100%;
    float: none;
    display: block;
    padding: 15px 0px 15px;
  }
  .md-tabs-normal .tab-content .step .title:before {
    display: none;
  }
  .md-tabs-normal .tab-content .step .step-content {
    width: 100%;
    float: none;
    display: block;
    padding: 20px 20px 20px 20px;
  }
  .md-tabs-normal .tab-content .tab-content-detail .title {
    padding: 44px 0px 17px;
  }
  .md-tabs-normal .tab-content .tab-content-detail .content-detail img {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .md-tabs-normal .tab-nav .nav .nav-item a {
    padding: 40px 10px 40px 10px;
    font-size: 15px;
  }
}
@media screen and (min-width: 992px) {
  .md-tabs-normal .tab-nav .container {
    position: relative;
  }
  .md-tabs-normal .tab-nav .signup {
    display: block;
    width: 166px;
    height: 152px;
    background: url(../images/bg-signup.png) no-repeat center center;
    position: absolute;
    right: 0px;
    top: -30px;
    text-align: center;
    color: #FFF;
  }
  .md-tabs-normal .tab-nav .signup a {
    display: block;
    padding: 46px 10px 0px 10px;
    color: #FFF;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
  }
  .md-tabs-normal .tab-nav .signup a span {
    display: block;
  }
}
@media screen and (min-width: 1215px) {
  .md-tabs-normal .tab-content .step .title .number span {
    width: 72px;
    height: 72px;
    font-size: 32px;
    padding: 10px 0px 0px 0px;
  }
  .md-tabs-normal .tab-content .step .title .is-title {
    font-size: 24px;
  }
}
// custom
  
.investor-services img{
    height:50px;
    // width:auto;
}
.investor-services .title{
    color: #13538a;
    font-weight:800;
    font-size: 22px;
    margin: 0px 0px 20px 0px;
    font-weight: 500;
}
.investor-services .service{
    font-weight: 500;
}