@import "~bootstrap/scss/bootstrap.scss";
@import url("~semantic-ui-css/semantic.min.css");
@import url("./vendors/boostrap-select/bootstrap-select.css");
@import "css/style";
@import "css/how-it-works";
@import "css/custom";
@import "css/deals";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import "form";
@import "loader";

.container {
  max-width: 1400px !important;
}

.page-choose-account .main-content .col-md-4 .ctn-inner .button-group button {
  width: 100%;
}

.page-create-account {
  &.investor-account {
    &.investor-account-01 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

/*/Pages /**/
// .page-deals.page-all-deals {
//   padding-top: 8px !important;
// }

/** Profile **/
.previewIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 125px;
    max-height: 125px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }
}

.page-ipos {
  padding: 141px 0px 66px;
}

.editor-class {
  height: 140px;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.nav-link-top {
  font-size: 16px;
  font-weight: 500;
}

.pconfirm {
  font-size: 16px;
}

.page-cornerstone {
  padding-top: 80px !important;
}

// .page-footer {
//   position: absolute;
//   bottom: 0;
// }
