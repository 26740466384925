.page-header {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.page-main,
.page-login {
  padding-top: 155px !important;
}

// .page-deals {
//   padding: 80px 0px 66px;
// }

.form-create-account {
  padding-top: 200px !important;
  padding-bottom: 260px !important;
}

.page-login .form-normal h1 {
  margin-bottom: 0px;
}

.dropdown-item.active.selected {
  background-color: #fff !important;
  color: #000 !important;
}

.how_you_know_us span.check-mark:after {
  top: 8px !important;
}

.content-detail p {
  font-size: 22px;
}

.mt-60 {
  margin-top: 60px;
}

.bl-form-regitester {
  background: #fbfbfb;
  padding: 70px 0px;
}

h3.mission {
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 24px;
  color: #000;
}

.how-it-works {
  padding: 70px 0px 90px;
}

.md-header-desktop,
.md-header-mobile,
.page-header-loggedin {
  background: #c0dff2;
}

.md-header-desktop {
  padding: 10px 0px 15px;
  // padding: 28px 0px 38px;
}

.md-header-mobile {
  padding: 20px 0px;
}

.investor-services {
  margin-top: 0px;
}

.btn {
  background-color: #008ad5;
  font-size: 18px;
  font-weight: 600;
}

.md-tabs .tab-nav .nav-item a.active:after {
  background: #008ad5;
}

.md-tabs-normal .tab-content .step {
  border-bottom: 0px solid #fff;
}

.md-header-mobile .top-navtigation .toggle-menu .icon span {
  background: #193f5c;
}

.btn.btn-outline-primary {
  background: #fafbfc;
  border: 0px solid #193f5c;
  color: #193f5c;
}

.rdt_TableCol {
  text-align: left !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.md-top-deal {
  padding-top: 125px !important;
}

.btn.btn-outline-primary {
  background: #008ad5;
  border: 1px solid #008ad5;
  color: #fff;
}
.btn.btn-outline-primary:hover {
  color: #193f5c;
  background: #fff;
  border: 1px solid #008ad5;
}
.rdt_TableCell {
  text-align: left !important;
}

i.huge.icon,
i.huge.icons {
  font-size: 2em;
}

@media screen and (max-width: 992px) {
  .bl-form-regitester {
    background: #fbfbfb;
    padding: 0px 0px 0px !important;
  }
  .bl-form-regitester .register-account {
    margin-top: 50px;
  }
  .md-tabs-normal .tab-nav .nav .nav-item a {
    padding: 20px 20px 20px 20px;
    line-height: 120%;
  }
  .md-tabs-normal .tab-nav .nav {
    margin-left: -13px;
  }
  .investor-services {
    margin-top: 30px;
  }
  .mt-60 {
    margin-top: 10px;
  }
  .how-it-works {
    padding: 40px 0px 30px;
  }
}

.bidnow-title {
  white-space: normal !important;
  overflow: visible !important;
}

ul.ds {
  margin-bottom: 30px;
  margin-left: 30px;
}

ul.ds li {
  list-style: disc;
}
